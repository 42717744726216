import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import axios from "../utils/axios";
//import { removeUserSession } from "../utils/Common";
import Header from "../components/Header";
import Footer from "../components/Footer";
import moment from "moment";
//import { useDownloadExcel } from "react-export-table-to-excel";
import { utils, writeFileXLSX } from "xlsx";
import { debounce } from 'lodash';


function Recap() {
  const [data, setData] = useState([]);
  const [range, setRange] = useState([]);
  //const history = useHistory();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [price, setPrice] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [checkRangeDate, setCheckRangeDate] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState("0");
  const [hotels, setHotels] = useState([]);

  const getListHotel = async () => {
    try {
      const response = await axios.get('api/list/hotel'); 
      if (response.status === 200) {
        //console.log("list hotel : ", response.data.data);
        const hotelNames = response.data.data.map((hotel) => ({
          id_hotel: hotel.id_hotel,
          hotel_name: hotel.nm_hotel,
        }));
        //console.log("hotelNames: ", hotelNames);
        setHotels(hotelNames);
      }
    } catch (error) {
      console.error('Error fetching the hotel list:', error.message);
    }
  };

  const getListRec = async (startDate, endDate) => {
    try {
      setSearching(true);
      setIsLoading(true);
      const response = await axios.get(
        `api/recap/data/${startDate}/${endDate}`
      );
      // console.log(startDate);
      // console.log("recap data:", response.data.data);
      setData(response.data.data);
      setRange(response.data.range);
      setDataFetched(true);
    } catch (err) {
      //console.log(err);
    } finally {
      setSearching(false);
      setIsLoading(false);
      //console.log("DONE. range : ", Range);
    }
  };

  const handleSearch = (e) => {
    if (startDate > endDate) {
      // If start date is greater than end date
      setCheckRangeDate(true);
      //console.log("check range date : ", checkRangeDate);
    } else {
      setCheckRangeDate(false);
      //console.log("check range date : ", checkRangeDate);
    }
    e.preventDefault();
    //console.log("selected hotel :", selectedHotel)
    getListRec(startDate, endDate);
      setFormSubmitted(true);
  
    setDataFetched(true);
  };

  const handlePriceChange = (e) => {
    const formattedPrice = e.target.value
    //   .replace(/\D/g, "")
    //   .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // console.log("formatted price :", formattedPrice)
    setPrice(e.target.value);
  };

  // Helper function to get total amount for a specific hotel on a specific date
  const getTotalAmount = (hotel, date) => {
    const ordersOnDate = data.filter(
      (d) => d.nm_hotel === hotel && d.order_date === date
    );
    return ordersOnDate.reduce((total, order) => {
      return total + order.M_amount + order.A_amount + order.E_amount;
    }, 0);
  };

  // Helper function to get total amount for a specific hotel across all dates
  const getTotalAmountForHotel = (hotel) => {
    return range.reduce((total, date) => {
      const amount = getTotalAmount(hotel, date);
      return total + amount;
    }, 0);
  };

  // Helper function to get total amount for a specific date
  const getTotalAmountForDate = (date) => {
    return data.reduce((total, order) => {
      if (order.order_date === date) {
        return total + order.M_amount + order.A_amount + order.E_amount;
      }
      return total;
    }, 0);
  };

  // Render the table rows
  const renderTableData = () => {
    // Sort the data by hotel_id
    if (selectedHotel === "0") {
    const sortedData = [...data].sort((a, b) => a.hotel_id - b.hotel_id);
    const hotelNames = [...new Set(sortedData.map((item) => item.nm_hotel))]; // Get unique hotel names
    return hotelNames.map((hotel, index) => {
      const totalAmountForHotel = getTotalAmountForHotel(hotel);
      const totalPrice =
        totalAmountForHotel *
        parseFloat(price.replace(".", "").replace(",", "."));

      return (
        <tr key={index}>
          <td>{hotel}</td>
          {range.map((date, i) => (
            <td
              style={{
                textAlign: "center",
              }}
              key={i}
            >
              {getTotalAmount(hotel, date)}
            </td>
          ))}
          <td
            style={{
              textAlign: "center",
            }}
          >
            {totalAmountForHotel.toLocaleString()}
          </td>
          {price && (
            <td
              style={{
                textAlign: "center",
              }}
            >
              {totalPrice.toLocaleString()}
            </td>
          )}
        </tr>
      );
    });
    }
  };

  // Render the total row
  const renderTotalRow = () => {
    const totals = range.map((date) => {
      return data.reduce((total, hotel) => {
        const amount = getTotalAmount(hotel.nm_hotel, date);
        return total + amount;
      }, 0);
    });

    const totalsForDate = range.map((date) => getTotalAmountForDate(date));

    // Calculate total for all dates (sum of totalsForDate)
    const totalAll = totalsForDate.reduce((total, amount) => total + amount, 0);

    const priceTotalTotal =
      totalAll * parseFloat(price.replace(".", "").replace(",", "."));

    return (
      <tr>
        <th>Total by Date</th>
        {totalsForDate.map((total, index) => (
          <th
            style={{
              textAlign: "center",
            }}
            key={index}
          >
            {total.toLocaleString()}
          </th>
        ))}
        <th
          style={{
            textAlign: "center",
          }}
        >
          {totalAll.toLocaleString()}
        </th>
        {price && (
          <th
            style={{
              textAlign: "center",
            }}
          >
            {priceTotalTotal.toLocaleString()}
          </th>
        )}
      </tr>
    );
  };

  const renderTableDataByHotel = () => {
    const selectedHotelData = data.filter(
      (item) => item.hotel_id === parseInt(selectedHotel)
    );
    const departments = [...new Set(selectedHotelData.map((item) => item.nm_department))];
  
    let totalByShiftMorning = 0;
    let totalByShiftAfternoon = 0;
    let totalByShiftEvening = 0;
  
    return (
      <>
        {/* Render Morning Shift */}
        <tr>
          <th
            colSpan={price ? range.length + 3 : range.length + 2}
            style={{
              textAlign: "center",
              border: "1px solid black",
              backgroundColor: "#a3daff",
              padding: "4px",
            }}
          >
            MORNING
          </th>
        </tr>
        <tr>
          <th style={{ verticalAlign: "middle", border: "1px solid black", padding: "4px" }}>Departments</th>
          {range.map((date, index) => (
            <th key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
              {moment(date).format("D")}
            </th>
          ))}
          <th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total</th>
          {price && (<th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total Price</th>)}
        </tr>
  
        {departments.map((department, index) => {
          let departmentTotal = 0;
          return (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "4px" }}>{department}</td>
              {range.map((date, i) => {
                const departmentData = selectedHotelData.find(
                  (item) =>
                    item.nm_department === department &&
                    item.order_date === date
                );
                const shiftAmount = departmentData ? departmentData.M_amount : 0;
                departmentTotal += shiftAmount;
                return (
                  <td
                    key={i}
                    style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}
                  >
                    {shiftAmount || 0}
                  </td>
                );
              })}
              <td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>{departmentTotal}</td>
              {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(departmentTotal * price)}</td> )}
            </tr>
          );
        })}
  
        <tr style={{ fontWeight: "bold" }}>
          <td style={{ border: "1px solid black", padding: "4px" }}>Total Per Day</td>
          {range.map((date, index) => {
            const totalForDay = selectedHotelData
              .filter((item) => item.order_date === date)
              .reduce((total, item) => total + item.M_amount, 0);
            totalByShiftMorning += totalForDay;
            return (
              <td key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
                {totalForDay}
              </td>
            );
          })}
          <td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>{totalByShiftMorning}</td>
          {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(totalByShiftMorning * price)}</td> )}
        </tr>
  
        {/* Render Afternoon Shift */}
        <tr>
          <th
            colSpan={price ? range.length + 3 : range.length + 2}
            style={{
              textAlign: "center",
              border: "1px solid black",
              backgroundColor: "#fff9a3",
              padding: "4px"
            }}
          >
            AFTERNOON
          </th>
        </tr>
        <tr>
          <th style={{ verticalAlign: "middle", border: "1px solid black", padding: "4px" }}>Departments</th>
          {range.map((date, index) => (
            <th key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
              {moment(date).format("D")}
            </th>
          ))}
          <th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total</th>
          {price && (<th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total Price</th>)}
        </tr>
  
        {departments.map((department, index) => {
          let departmentTotal = 0;
          return (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "4px" }}>{department}</td>
              {range.map((date, i) => {
                const departmentData = selectedHotelData.find(
                  (item) =>
                    item.nm_department === department &&
                    item.order_date === date
                );
                const shiftAmount = departmentData ? departmentData.A_amount : 0;
                departmentTotal += shiftAmount;
                return (
                  <td
                    key={i}
                    style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}
                  >
                    {shiftAmount || 0}
                  </td>
                );
              })}
              <td style={{ textAlign: "center", border: "1px solid black" , padding: "4px"}}>{departmentTotal}</td>
              {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(departmentTotal * price)}</td>)}
            </tr>
          );
        })}
  
        <tr style={{ fontWeight: "bold" }}>
          <td style={{ border: "1px solid black", padding: "4px" }}>Total Per Day</td>
          {range.map((date, index) => {
            const totalForDay = selectedHotelData
              .filter((item) => item.order_date === date)
              .reduce((total, item) => total + item.A_amount, 0);
            totalByShiftAfternoon += totalForDay;
            return (
              <td key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
                {totalForDay}
              </td>
            );
          })}
          <td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>{totalByShiftAfternoon}</td>
          {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(totalByShiftAfternoon * price)}</td>)}
        </tr>
  
        {/* Render Evening Shift */}
        <tr>
          <th
            colSpan={price ? range.length + 3 : range.length + 2}
            style={{
              textAlign: "center",
              border: "1px solid black",
              backgroundColor: "#d1d1d1",
              padding: "4px"
            }}
          >
            EVENING
          </th>
        </tr>
        <tr>
          <th style={{ verticalAlign: "middle", border: "1px solid black", padding: "4px" }}>Departments</th>
          {range.map((date, index) => (
            <th key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
              {moment(date).format("D")}
            </th>
          ))}
          <th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total</th>
          {price && (<th style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Total Price</th>)}
        </tr>
  
        {departments.map((department, index) => {
          let departmentTotal = 0;
          return (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "4px" }}>{department}</td>
              {range.map((date, i) => {
                const departmentData = selectedHotelData.find(
                  (item) =>
                    item.nm_department === department &&
                    item.order_date === date
                );
                const shiftAmount = departmentData ? departmentData.E_amount : 0;
                departmentTotal += shiftAmount;
                return (
                  <td
                    key={i}
                    style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}
                  >
                    {shiftAmount || 0}
                  </td>
                );
              })}
              <td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>{departmentTotal}</td>
              {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(departmentTotal * price)}</td>)}
            </tr>
          );
        })}
  
        <tr style={{ fontWeight: "bold" }}>
          <td style={{ border: "1px solid black", padding: "4px" }}>Total Per Day</td>
          {range.map((date, index) => {
            const totalForDay = selectedHotelData
              .filter((item) => item.order_date === date)
              .reduce((total, item) => total + item.E_amount, 0);
            totalByShiftEvening += totalForDay;
            return (
              <td key={index} style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>
                {totalForDay}
              </td>
            );
          })}
          <td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>{totalByShiftEvening}</td>
          {price && (<td style={{ textAlign: "center", border: "1px solid black", padding: "4px" }}>Rp{new Intl.NumberFormat('id-ID').format(totalByShiftEvening * price)}</td>)}
        </tr>
      </>
    );
  };
  

  useEffect(() => {
    const debouncedGetListHotel = debounce(getListHotel, 300);
    debouncedGetListHotel(); 
  }, []);

  const tableRef = useRef(null);

  const xport = React.useCallback(() => {
    /* Create worksheet from HTML DOM TABLE */
    setTimeout(() => {
      const wb = utils.table_to_book(tableRef.current);
  
      const getFilename = (startDate, endDate, price) => {
        const pricePart = price ? " Rp" + price : "";
        return (
          "Recap Catering - " +
          moment(startDate).format("DD/MM/YYYY") +
          " - " +
          moment(endDate).format("DD/MM/YYYY") +
          pricePart +
          ".xlsx"
        );
      };
      const filename = getFilename(startDate, endDate, price);
      /* Export to file (start a download) */
      writeFileXLSX(wb, filename);
    }, 100); // Wait 100ms before capturing the table
  }, [tableRef, startDate, endDate, price]);
  

  return (
    <div>
      <Header />

      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "auto" }}
      >
        <Form className="d-flex flex-row" onSubmit={handleSearch}>
          <Form.Group className="mb-3 me-2">
            <Form.Label style={{ fontSize: "11px" }}>From</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              style={{ fontSize: "11px" }}
            />
          </Form.Group>

          <Form.Group className="mb-3 me-2">
            <Form.Label style={{ fontSize: "11px" }}>Until</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              style={{ fontSize: "11px" }}
            />
          </Form.Group>

          {/* Dropdown for selecting the hotel */}
          <Form.Group className="mb-3 me-2">
            <Form.Label style={{ fontSize: '11px' }}>Hotel</Form.Label>
            <Form.Select
              value={selectedHotel}
              onChange={(e) => setSelectedHotel(e.target.value)}
              style={{ fontSize: '11px' }}
            >
              <option value="0">All Hotels</option>
              {hotels.map((hotel) => (
                <option key={hotel.id_hotel} value={hotel.id_hotel}>
                  {hotel.hotel_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3 me-2">
            <Form.Label style={{ fontSize: "11px" }}>Price</Form.Label>
            <Form.Control
              type="text"
              value={price}
              onChange={handlePriceChange}
              style={{ fontSize: "11px", width: '100px'  }}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="align-self-end mb-3 me-2"
            style={{ fontSize: "11px" }}
          >
            {searching ? "Searching..." : "Search"}
          </Button>
        </Form>
      </Container>

      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "auto", marginBottom: "60px" }}
      >
        {formSubmitted ? (
          <Card
            className="text-justify"
            style={{
              width: "auto",
              fontSize: "11px",
              minWidth: "500px",
              backgroundColor: "#f8f8f8",
            }}
          >
            <Card.Body>
              <Card.Title>CATERING RECAPITULATION</Card.Title>
              <Card.Text>Set date range to get report</Card.Text>
              {startDate &&
                endDate &&
                !checkRangeDate &&
                dataFetched &&
                !isLoading && (
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    Order from: {moment(startDate).format("LL")}
                    {"  to  "}
                    {moment(endDate).format("LL")}
                  </Card.Text>
                )}
              {price && data.length > 0 && (
                <Card.Text>Entered Price : Rp{new Intl.NumberFormat('id-ID').format(price)}</Card.Text>
              )}
              {!checkRangeDate ? (
                formSubmitted ? (
                  dataFetched && !isLoading ? (
                    data.length > 0 ? (
                      <Table
                        className="table-bordered custom-table-2"
                        responsive="sm"
                        ref={tableRef}
                      >
                        <thead>
                      {selectedHotel === "0" ? (
                        <tr>
                          <th>Hotel Name</th>
                          {range.map((date, index) => (
                            <th key={index} style={{ textAlign: "center" }}>
                              {moment(date).format("LL")}
                            </th>
                          ))}
                          <th>Total by Hotel</th>
                          {price && <th>Total Price</th>}
                        </tr>
                      ) : (
                        <tr>
                          <th colSpan={range.length + 3} style={{ textAlign: "center" }}>
                            {/* Convert selectedHotel to a number before accessing the array */}
                            {hotels.find(hotel => hotel.id_hotel === parseInt(selectedHotel))?.hotel_name || "Couldn't find Hotel"}
                          </th>
                        </tr>
                      )
                      }
                    </thead>
                    <tbody>
                      {selectedHotel === "0" ? renderTableData() : renderTableDataByHotel()}
                      {selectedHotel === "0" && renderTotalRow()}
                    </tbody>
                      </Table>
                    ) : (
                      <p>No data available for the selected date.</p>
                    )
                  ) : (
                    <p>Loading...</p>
                  )
                ) : (
                  <p></p>
                )
              ) : (
                <p>Incorrect date range. Please check your dates.</p>
              )}
              {!isLoading && dataFetched && data.length > 0 && (
                <Button
                  variant="primary"
                  type="submit"
                  className="align-right me-2"
                  style={{ fontSize: "11px" }}
                  onClick={xport}
                >
                  Download Excel
                </Button>
              )}
            </Card.Body>
          </Card>
        ) : (
          <Card
            className="text-justify"
            style={{
              width: "auto",
              fontSize: "11px",
              minWidth: "600px",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "210px",
              backgroundColor: "#f8f8f8",
            }}
          >
            <Card.Body
              style={{
                paddingTop: "4px",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <Card.Title
                style={{
                  fontWeight: "700",
                }}
              >
                CATERING RECAPITULATION
              </Card.Title>
              <Card.Text>Set date range to get report.</Card.Text>
            </Card.Body>
          </Card>
        )}
      </Container>

      <Footer />
    </div>
  );
}

export default Recap;
